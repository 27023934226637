import { Flex, Image, List, Text, Title } from '@mantine/core';
import { ReactComponent as TickIcon } from 'assets/icons/green-tick.svg';
import BoostProductivityIllustration from 'assets/actions/productivity-illustration.svg';
import styles from './styles.module.scss';

const infoItems = [
  {
    title: 'Automated Task Management:',
    description: 'Effortlessly create and manage Jira tickets directly from Slack.'
  },
  {
    title: 'Seamless Event Scheduling:',
    description: 'Schedule and update events without leaving your Slack workspace.'
  },
  {
    title: 'Efficient CRM Updates:',
    description:
      'Instantly create, update, and manage contacts, opportunities and more within your CRM, all through Slack.'
  }
];

const InformationPanel = () => {
  return (
    <div className={styles.panelContainer}>
      <div className={styles.infoContainer}>
        <Title className={styles.title}>Boost your productivity with Actions</Title>
        <Flex direction='column' gap={20}>
          {infoItems.map((item, index) => (
            <List key={index} icon={<TickIcon />} classNames={{ itemWrapper: styles.ListItems }}>
              <List.Item>
                <Text className={styles.infoText}>
                  <span>{item.title}</span> {item.description}
                </Text>
              </List.Item>
            </List>
          ))}
        </Flex>
      </div>

      <div className={styles.illustrationContainer}>
        <Image src={BoostProductivityIllustration} />
      </div>
    </div>
  );
};

export default InformationPanel;
