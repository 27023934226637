import PropTypes from 'prop-types';
import { Flex, Text, Title } from '@mantine/core';
import Button from 'components/Button';
import styles from './styles.module.scss';

const SubmitRequestSection = ({ setShowRequestModal }) => {
  return (
    <Flex direction='column' gap={8} className={styles.submitRequestContainer}>
      <Title size={18} fw={600} lh={1.8}>
        Can&apos;t find what you need?
      </Title>
      <Text size={14} fw={400} lh={1.5} maw={1280} c='#5E5873'>
        Submit your request and provide us with details about the specific integration you would
        like us to focus on. Your input is valuable in helping us prioritize and tailor our efforts
        to meet your needs effectively.
      </Text>
      <Button className={styles.requestBtn} onClick={() => setShowRequestModal(true)}>
        Submit request
      </Button>
    </Flex>
  );
};

SubmitRequestSection.propTypes = {
  setShowRequestModal: PropTypes.func.isRequired
};

export default SubmitRequestSection;
